import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Post from '../templates/post/post'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
// import YouTubeSubscribe from '../components/youtubeSubscribe'

// import dnaLogo from '../../static/img/DNA-Black.png'
import DnaLogo from '../../static/svg/DNA-Black.svg'
// import logo from '../../static/img/logo-yellowbg-white.svg'

import youtubeURL from '../../site/settings/main_youtube_url.json'

import '../scss/index.scss'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const BlogIndex: React.FC<Props> = ({ data, location }: Props) => {
  const posts = data.remark.posts
  const meta = data.site?.meta

  // const youtubeURL = "https://www.youtube.com/embed/C6KdFta98W8?rel=0"

  const channelid = 'UCxwG9Jwuidhhm1DRLC9Vwjw'

  return (
    <Layout location={location}>
      <div className="bg-sec">
        {/* <img className="bg-logo d-none d-lg-block" src={dnaLogo} /> */}
        <DnaLogo className="bg-logo d-none d-lg-block" />
      </div>
      {/* <img src={logo} /> */}
      <Helmet>
        <script src="https://apis.google.com/js/platform.js"></script>
      </Helmet>

      <Meta site={meta} />
      <div className="main-block ">
        {/* <img src={} alt=""/> */}
        <div className="row">
          <div id="youtube-section" className="col-12 col-lg-6 order-lg-2  ">
            <div className=" px-4 wrapper">
              <div className="youtube-block">
                <iframe
                  className=""
                  width="100%"
                  height="100%"
                  src={youtubeURL.video_url} 

                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div id="channel-links" className=" row">
                <div className="col-12 order-lg-2 text-secondary text-center">
                  <small>select the series to watch</small>
                </div>
                <p className="h6 col-12 order-lg-1 text-secondary text-center mt-lg-3 mb-0">
                  <a
                    className="link"
                    href="https://www.youtube.com/watch?v=sn6NFQr63l4&list=PLtzB6RsI2Tzj_pT6g2hOSOGUIQZfK3pSo"
                    target="_blank"
                  >
                    {' '}
                    SINGLE & READY
                  </a>{' '}
                  <span className="text-accent">-</span>
                  <a
                    className="link"
                    href="https://www.youtube.com/watch?v=_J9Et1EJuqM&list=PLtzB6RsI2TzhFUlu3d8u8gefveWZjs9H8"
                    target="_blank"
                  >
                    {' '}
                    WORSHIP CULTURE
                  </a>{' '}
                  <span className="text-accent d-lg-none">-</span>
                  <br className="d-none d-lg-block" />
                  <a
                    className="link"
                    href="https://www.youtube.com/watch?v=Vn2UrAA12Nk&list=PLtzB6RsI2TzgH7M3_iXOj1FynMnYb9-Fq"
                    target="_blank"
                  >
                    {' '}
                    DNA 
                  </a>{' '}
                  <span className="text-accent">-</span>
                  <a
                    className="link"
                    href="https://www.youtube.com/watch?v=NVQTRZMOQOg&list=PLtzB6RsI2Tzg6ngEFCjCqC0w3xE7bAxRK&index=1"
                    target="_blank"
                  >
                    {' '}
                    BREAK THE SILENCE
                  </a>{' '}
                  <span className="text-accent  d-lg-none ">-</span>
                  <br className="d-none d-lg-block" />
                  {/* <a
                    className="link"
                    href="https://www.youtube.com/watch?v=1kwKdI9CSQ0&list=PLtzB6RsI2TzgG3B-YJJneWvXQQZdI5_Ic"
                  >
                    {' '}
                    WORSHIP MOMENTS{' '}
                  </a> */}

<a
                    className="link"
                    href="https://www.youtube.com/watch?v=NHO21YSDDcU"
                  >
                    {' '}
                    THE STORY OF GRACE{' '}
                  </a>{' '}
                  <span className="text-accent">-</span>
                  <a
                    className="link"
                    href="https://www.youtube.com/watch?v=1kwKdI9CSQ0&list=PLtzB6RsI2TzgG3B-YJJneWvXQQZdI5_Ic"
                  >
                    {' '}
                    WORSHIP MOMENTS{' '}
                  </a>
                  <span className="text-accent d-lg-none">-</span>
                  <br className="d-none d-lg-block" />
                </p>
              </div>
            </div>
          </div>
          <div
            id="about-section"
            className=" col-12 col-lg-4 offset-lg-1 order-lg-1 "
          >
            <div className="p-4 wrapper">
              <div className="h1 title">
                Serve God, <br />
                Serve Nations!
              </div>
              <div className="mb-3 desc">
                Robin & Lenu is passionate about seeking to revive lost
                identities, seeing people encounter God and out of that
                overflow, minister to the ones God has placed around them.
              </div>
              <div className="h3 subscription-link">
                <strong></strong>
                <a className="btn btn-primary" style={{backgroundColor:'#ff5053'}} href="https://www.youtube.com/robinandlenu?sub_confirmation=1">Subscribe Now!</a>
                <br />
                {/* <a
                  className="g-ytsubscribe "
                  data-channelid="UCxwG9Jwuidhhm1DRLC9Vwjw"
                  data-layout="default"
                  data-theme="dark"
                  data-count="hidden"
                ></a> */}
                {/* <div id="YouTubeSubscribe"></div> */}
                {/* <YouTubeSubscribe
                  // channelName={channelName}
                  channelid={'UCxwG9Jwuidhhm1DRLC9Vwjw'}
                  theme={'default'}
                  layout={'default'}
                  count={'hidden'}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer ">
        <div className="container-fluid text-center ">
          <p className="col-12 d-lg-none mb-0">
            <a
              className="link reverse-md "
              href="https://www.youtube.com/channel/UCxwG9Jwuidhhm1DRLC9Vwjw"
              target="_blank"
            >
              YOUTUBE{' '}
            </a>{' '}
            <span className="text-accent"> - </span>
            <a
              className="link reverse-md "
              href="https://www.instagram.com/robinandlenu/"
              target="_blank"
            >
              INSTAGRAM{' '}
            </a>{' '}
            <span className="text-accent"> - </span>
            <a
              className="link reverse-md "
              href="https://www.facebook.com/robinandlenu/"
              target="_blank"
            >
              FACEBOOK{' '}
            </a>
          </p>
          <small>Robin & Lenu William © {new Date().getFullYear()}</small>
        </div>
      </div>

      {/* {posts.map((post, i) => (
        <Post
          data={post as PostByPathQuery}
          options={{
            isIndex: true,
          }}
          key={i}
        />
      ))} */}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query IndexQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        adsense
      }
    }
    remark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            path
            category
            tags
            description
            date(formatString: "YYYY/MM/DD")
          }
        }
      }
    }
  }
`
